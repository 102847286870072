import React from "react"
import "../sass/main.scss"
import { Layout, SEO, HeadSection, Scrollable, Image } from "../components"
import { Row, Col, Container } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const EuropeanUnionPage = () => {
  const data: any = useStaticQuery(graphql`
    query {
      european: file(relativePath: { eq: "eu_logo_en.jpg" }) {
        publicURL
      }
    }
  `)
  const headTitle = (): string => {
    return "Satalia is working on an innovative routing project, co-financed by the European Union."
  }
  const headDescription = (): string => {
    return `Following a successful application to the European Regional Development fund, we're now co-financing the development of a route optimisation product aimed to meet the ever increasing demands of our clients, and their customers. The project is funded both by the European Union and Satalia.`
  }
  return (
    <Layout step={3} smallHeader={true}>
      <SEO
        title="Satalia | EU | co-funded routing product"
        description="We're working on a routing product that optimises same day deliveries, co-financed by the EU and Satalia. "
        twitterTitle="Satalia | EU | co-funded routing project"
        twitterDescription="We're working on a routing product that optimises same day deliveries, co-financed by the EU and Satalia. "
        openGraphTitle="Satalia | EU | co-funded routing project"
        openGraphDescription="Same-day routing product, co-funded by Satalia and the EU."
        meta={[{ name: "robots", content: "noindex, follow" }]}
      />
      <section id="case-study" className="mb-25">
        <Container>
          <HeadSection
            title={headTitle()}
            subtitle={""}
            description={headDescription()}
          >
            <Col xs="3" sm="8" md="10" lg="8">
              <div className="st-block st-block--head">
                <img src={data.european.publicURL} alt="eu-fund" />
              </div>
            </Col>
          </HeadSection>
        </Container>
      </section>
      <section id="case-study-detail" className="pb-75">
        <Container className="position-relative">
          <Row>
            <Col xs="3" sm="8" md="8" lg="6" className="offset-lg-4">
              <p>
                The aim of the project is to create a world-leading route
                optimisation product that focuses on optimising the routes of
                same day deliveries. This would significantly reduce delivery
                costs, increase profitability and vastly improve the customer
                experience of any company using it.
              </p>
              <br />
              <p>
                This project is co-financed by the European Regional Development
                Fund under Priority 1 "Promotion of Research, Development and
                Innovation" 01.2.1-LVPA-T-848 Feature SMART FDI. The total value
                of the project is €1,242,234.31, of which €647,778.48 is from
                the European Regional Development Fund and the remaining amount
                is funded by Satalia. Project activities started in January,
                2020. The project number is no. Nr. 01.2.1-LVPA-T-848-02-0008.
              </p>
              <p>Project complete date: 30th September 2022.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default EuropeanUnionPage
